import { IExtendedModel } from './extended-model';

export enum RoundRole {
    User = 'user',
    Assistant = 'assistant',
    System = 'system'
}

/**
 * Represents a round in a dialog.
 */
export class Round {
    text: string;
    role: RoundRole;
    timestamp: string;
    num_tokens: number;
    metadata: RoundMetadata;

    constructor(partial?: Partial<Round>) {
        this.text = partial?.text || '';
        this.role = this.isValidRole(partial?.role) ? partial?.role : RoundRole.User;
        this.timestamp = partial?.timestamp || '';
        this.num_tokens = partial?.num_tokens || 0;

        // Ensure `metadata` is always initialized with default `hide_row`
        this.metadata = {
            hide_row: false, // Default value for hide_row
            ...partial?.metadata, // Merge with provided metadata
        };
    }

    private isValidRole(role?: string): boolean {
        return role !== undefined && Object.values(RoundRole).includes(role as RoundRole);
    }

    /**
     * Returns whether the round is hidden from the user.
     */
    get isHidden(): boolean {
        // This method always returns undefined for me and I can't figure out why :(
        return this.metadata?.hide_row ?? false;
    }

    /**
     * Returns whether the round is visible to the user.
     */
    get isVisible(): boolean {
        return !this.isHidden;
    }
}

/**
 * Represents a function call in a round.
 */
export interface RoundFunctionCall {
    function_name: string;
    parameters?: { [key: string]: any };
    result: { query_result?: object[] };
}

/**
 * Represents the metadata of a round.
 * This has some known properties and allows additional unknown properties.
 */
export interface RoundMetadata extends IExtendedModel {
    function_call?: RoundFunctionCall;
    link?: string;
    row_count?: number;
    col_count?: number;
    row_exceed_max?: boolean;
    col_exceed_max?: boolean;
    error?: string;
    show_results?: boolean;

    // hide_row is known, non-optional property
    hide_row?: boolean;
}

export class Dialog {
    rounds: Round[];

    constructor(partial?: Partial<Dialog>) {
        this.rounds = partial?.rounds?.map(round => new Round(round)) || [];
    }
}

class AgentSystem {
    thread_id: string;
    assistant_id: string;
    pending_runs: string[];

    constructor(partial?: Partial<AgentSystem>) {
        this.thread_id = partial?.thread_id || '';
        this.assistant_id = partial?.assistant_id || '';
        this.pending_runs = partial?.pending_runs || [];
    }
}

export class AppContext {
    agent_system: AgentSystem;

    constructor(partial?: Partial<AppContext>) {
        this.agent_system = new AgentSystem(partial?.agent_system);
    }
}

export class Session {
    session_id: string;
    user_id: string;
    created: string;
    dialog: Dialog;
    name: string;
    app_context: AppContext;

    constructor(partial?: Partial<Session>) {
        this.session_id = partial?.session_id || '';
        this.user_id = partial?.user_id || '';
        this.created = partial?.created || '';
        this.dialog = new Dialog(partial?.dialog);
        this.name = partial?.name || '';
        this.app_context = new AppContext(partial?.app_context);
    }
}

export class AssistantChatResponse {
    is_pending: boolean;
    session: Session;

    constructor(partial?: Partial<AssistantChatResponse>) {
        this.is_pending = partial?.is_pending || false;
        this.session = new Session(partial?.session);
    }
}

export class AssistantChatResponseParser {
    private response: AssistantChatResponse;

    constructor(response: AssistantChatResponse) {
        this.response = new AssistantChatResponse(response);
    }

    get sessionId(): string {
        return this.response.session.session_id;
    }

    get userId(): string {
        return this.response.session.user_id;
    }

    get createdDate(): Date {
        return new Date(this.response.session.created);
    }

    get rounds(): Round[] {
        return this.response.session.dialog.rounds;
    }

    get lastRound(): Round | undefined {
        const rounds = this.rounds;
        return rounds.length > 0 ? rounds[rounds.length - 1] : undefined;
    }

    get lastAssistantMessage(): Round | undefined {
        const rounds = this.rounds.filter(round => round.role === 'assistant');
        return rounds.length > 0 ? rounds[rounds.length - 1] : undefined;
    }

    get lastUserMessage(): Round | undefined {
        const rounds = this.rounds.filter(round => round.role === 'user');
        return rounds.length > 0 ? rounds[rounds.length - 1] : undefined;
    }
}

