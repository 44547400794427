<h2 mat-dialog-title>Submit feedback to GDCO Assistant Team</h2>

<mat-dialog-content class="mat-typography flex-form">
  <form [formGroup]="feedbackForm" id="feedbackForm" class="feedback-form">
    <mat-label id="checkbox-group-label">Provide additional feedback</mat-label>
    <mat-checkbox [formControl]="hasIncorrectResults">Contains incorrect or erroneous results</mat-checkbox>
    <mat-checkbox [formControl]="hasIncompleteResults">Lacks critical or complete details</mat-checkbox>
    <mat-checkbox [formControl]="misunderstoodPrompt">Misinterpreted my question or intent</mat-checkbox>
    <mat-checkbox [formControl]="hasPresentationIssue">Issues with presentation or formatting</mat-checkbox>
    <mat-checkbox [formControl]="hasSecurityConcern">Raises security, privacy, or safety concerns</mat-checkbox>

    <mat-form-field appearance="fill" class="feedback-text-field">
      <mat-label>Please elaborate and describe what went wrong or how we could improve the results.</mat-label>
      <textarea matInput formControlName="feedbackText" rows="5"
        placeholder="Give as much detail as you can, but do not include any private or sensitive information."></textarea>
    </mat-form-field>

    <mat-label id="radio-group-label">May we contact you about your feedback?</mat-label>
    <mat-radio-group [formControl]="contactPreference" name="contact" class="radio-group" required
      aria-labelledby="radio-group-label">
      <mat-radio-button class="radio-button" value="yes">Yes</mat-radio-button>
      <mat-radio-button class="radio-button" value="no">No</mat-radio-button>
    </mat-radio-group>

    <mat-error *ngIf="contactPreference.hasError('required')">
      Please select an option.
    </mat-error>

    <p>Signed in as {{ userEmail }}</p>
  </form>

  <div class="privacy-section">
    <span>
      <a href="https://go.microsoft.com/fwlink/?linkid=2260166" target="_blank" rel="noopener noreferrer">Learn more</a>
      about how this data is used and your rights. By pressing Submit, your feedback will be used to improve Microsoft
      products and services.
      <a href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" rel="noopener noreferrer">Privacy
        statement.</a>
    </span>
  </div>

</mat-dialog-content>

<mat-dialog-actions class="dialog-actions-right" align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()"
    [disabled]="!feedbackText || !contactPreference">Submit</button>
</mat-dialog-actions>