import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GdcoAuth } from '@gdco/auth';
import { AssistantChatService } from '../services';
import { AssistantMessage, AssistantMessageFeedback } from '../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'assistant-chat-feedback-thumbup',
  templateUrl: './assistant-chat-feedback-thumbup.component.html',
  styleUrls: ['./assistant-chat-feedback-thumbup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AssistantChatFeedbackThumbup implements OnInit {
  feedbackForm: FormGroup;
  feedbackText: FormControl = new FormControl('', Validators.required);
  contactPreference: FormControl = new FormControl('yes', Validators.required);
  userEmail: string;
  message!: AssistantMessage;

  constructor(public dialogRef: MatDialogRef<AssistantChatFeedbackThumbup>,
    private formBuilder: FormBuilder,
    private readonly auth: GdcoAuth,
    private assistantChatService: AssistantChatService,
    @Inject(MAT_DIALOG_DATA) public data: { message: AssistantMessage }) {
    this.message = data.message;
  }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      feedbackText: this.feedbackText,
      contactPreference: this.contactPreference
    });

    this.userEmail = this.auth.currentUser.userPrincipalName;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.feedbackForm.valid) {

      const feedback = new AssistantMessageFeedback({
        source_id: this.assistantChatService.assistant?.assistant_id ?? '',
        source_name: this.assistantChatService.assistant?.shortname ?? '',
        source_data: this.message?.concatSegments() ?? '',
        source_data_timestamp: this.message.timeStamp?.toISOString() ?? '',
        session_id: this.assistantChatService.session?.session_id ?? '',
        ticket_id: this.assistantChatService.ticketId,
        user_id: this.auth.currentUser.userId,
        user_email: this.auth.currentUser.userPrincipalName,
        user_request: this.message.requestText ?? '',
        feedback_details: this.feedbackText.value,
        contact_preference: this.contactPreference.value,
        is_positive: true
      });

      this.assistantChatService
        .sendMessageFeedback(feedback)
        .then(() => {
          this.dialogRef.close(this.feedbackForm.value);
        });
    }
  }
}