export default [
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "alert-button",
    "displayName": "Alert Button",
    "url": "alert-button",
    "icon": "report",
    "owner": "",
    "flighting": "",
    "icmRouting": "MCIO-CIH-ArgusService/ArgusServiceEventStreaming",
    "moduleFile": "alert-button-app.module",
    "moduleName": "AlertButtonAppModule",
    "authorization": [
      {
        "resources": "AlertButtonApp"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "asset-readiness",
    "displayName": "Asset readiness",
    "url": "v2assetReadiness",
    "icon": "fiber_new",
    "owner": "",
    "flighting": "",
    "moduleFile": "asset-readiness-app.module",
    "moduleName": "AssetReadinessAppModule",
    "privateCloudEnabled": false,
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243",
    "authorization": [
      {
        "resources": [
          "MicrosoftData"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": [
          "AtLeastOneDbdPolicy"
        ],
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "cabling",
    "displayName": "Order Cable",
    "url": "cable",
    "icon": "add_shopping_cart",
    "owner": "",
    "icmRouting": "CSCP-SupplyChain-Internal/SupplyChainExecution",
    "flighting": "",
    "moduleFile": "cabling-app.module",
    "moduleName": "CablingAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "cissg-metadata",
    "displayName": "CISSG Metadata",
    "url": "cissgmetadata",
    "icon": "list_alt",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/CISSG",
    "flighting": "CissgMetadataApp",
    "moduleFile": "cissg-metadata-app.module",
    "moduleName": "CissgMetadataAppModule",
    "authorization": [
      {
        "resources": [
          "MicrosoftData",
          "CissgMetadataBasicRead"
        ]
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "dbds-iotmanagement",
    "displayName": "DBD IoT Management",
    "url": "dbdiotmanagement",
    "icon": "fiber_new",
    "owner": "",
    "flighting": "DBD-IOT-Management-Pilot",
    "moduleFile": "dbds-iotmanagement-app.module",
    "moduleName": "DbdsIotmanagementAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "dbds-v2",
    "displayName": "Data Bearing Devices",
    "url": "dbds",
    "icon": "dns",
    "owner": "",
    "icmRouting": "COIE-ITOps: DBD Risk Management/Triage",
    "moduleFile": "dbds-v2-app.module",
    "moduleName": "DbdsV2AppModule",
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243",
    "authorization": [
      {
        "resources": [
          "MicrosoftData"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": [
          "AtLeastOneDbdPolicy"
        ],
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "dcclosures",
    "displayName": "Data Center Closures",
    "url": "dcclosures",
    "icon": "exit_to_app",
    "owner": "",
    "flighting": "",
    "moduleFile": "dcclosures-app.module",
    "moduleName": "DcclosuresAppModule",
    "authorization": [
      {
        "resources": "DCClosuresApp"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "decommissions",
    "displayName": "Active Decommissions/Migrations",
    "url": "decommissions",
    "icon": "list",
    "owner": "",
    "icmRouting": "MCIO Common/DecommPD",
    "flighting": "",
    "moduleFile": "decommissions-app.module",
    "moduleName": "DecommissionsAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "digitized-procedure",
    "displayName": "CE Digital Procedures",
    "url": "digitalprocedures",
    "icon": "library_books",
    "owner": "",
    "icmRouting": "Critical Environment Service/Triage",
    "flighting": "",
    "moduleFile": "digitized-procedure-app.module",
    "moduleName": "DpAppModule",
    "redirects": [
      {
        "from": "dp",
        "to": "digitalprocedures"
      }
    ],
    "privateCloudEnabled": false,
    "appInsightsKey": "d99e6f84-3607-4556-852b-b182c1be6624",
    "authorization": [
      {
        "resources": "Procedure",
        "tenant": "CEServiceTest"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "dock-confirmations",
    "displayName": "Inbound Delivery Management",
    "url": "dockconfirmations",
    "icon": "today",
    "owner": "",
    "icmRouting": "CSCP-SupplyChain-Internal/SupplyChainExecution",
    "flighting": "",
    "moduleFile": "dock-confirmation-app.module",
    "moduleName": "DockConfirmationAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "fulfillments",
    "displayName": "Active Fulfillments",
    "url": "fulfillmentprojects",
    "icon": "assessment",
    "owner": "",
    "icmRouting": "CSCP-FAST-DeploymentServices/Deployment",
    "flighting": "",
    "moduleFile": "fulfillments-app.module",
    "moduleName": "FulfillmentsAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": true
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "inventory-mgmt",
    "displayName": "Inventory Management",
    "url": "inventory",
    "icon": "assignment",
    "owner": "",
    "icmRouting": "CO+IE-Hardware Inventory/Triage",
    "flighting": "",
    "moduleFile": "inventory-mgmt-app.module",
    "moduleName": "InventoryAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "pages": [
      {
        "name": "inventory-mgmt",
        "displayName": "Cycle Count",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/Spare Operations & ICQA (Michelin)"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "RMA",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/IAM Inbound Outbound"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "URMA",
        "url": "",
        "icon": "",
        "icmRouting": "CSCP-Execution-RMAService/Triage"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "Check-Out Management",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/Spare Operations & ICQA (Michelin)"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "Transfer Order",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/IAM Inbound Outbound"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "DBD Bin Drop",
        "url": "",
        "icon": "",
        "icmRouting": "COIE-ITOps: DBD Risk Management/Triage"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "DBD Shred",
        "url": "",
        "icon": "",
        "icmRouting": "COIE-ITOps: DBD Risk Management/Triage"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "Inventory Viewer",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "Inventory Asset Management",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "Asset Investigation or Change Request",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage",
        "icmRoutingRedirection": {
          "url": "https://gdcoapp.trafficmanager.net/requests/forms/2",
          "text": "Support tickets",
          "message": "Please use the link below to submit an asset inventory investigation or change request."
        }
      },
      {
        "name": "inventory-mgmt",
        "displayName": "BreakFix Check-In, Check-out, Consume",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/Spare Operations & ICQA (Michelin)"
      },
      {
        "name": "inventory-mgmt",
        "displayName": "Spares Inventory",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/Spare Operations & ICQA (Michelin)"
      }
    ],
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "label-printing",
    "displayName": "Label Printing",
    "url": "label-printing",
    "icon": "print",
    "owner": "",
    "icmRouting": "CO+IE-Hardware Inventory/IAM Inbound Outbound",
    "flighting": "",
    "moduleFile": "label-printing-app.module",
    "moduleName": "LabelPrintingAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "planned-maintenance",
    "displayName": "Planned Maintenance",
    "url": "plannedmaintenance",
    "icon": "queue",
    "owner": "",
    "flighting": "COI-PM",
    "icmRouting": "Critical Environment Service/Triage",
    "moduleFile": "planned-maintenance-app.module",
    "moduleName": "PlannedMaintenanceAppModule",
    "privateCloudEnabled": false,
    "authorization": [
      {
        "resources": "Procedure",
        "tenant": "CEServiceTest"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "policy-management",
    "displayName": "Policy Management",
    "url": "policymanagement",
    "icon": "how_to_reg",
    "owner": "",
    "icmRouting": "CO+I Permissions/Incident Manager",
    "flighting": "gdcopolicymanagement",
    "moduleFile": "policy-management.module",
    "moduleName": "PolicyManagementModule",
    "privateCloudEnabled": false,
    "authorization": [
      {
        "resources": "MicrosoftData"
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": "AtLeastOnePolicy",
        "action": "Manage"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "receive-mobile",
    "displayName": "Receive Mobile",
    "url": "receive-mobile",
    "icon": "fiber_new",
    "owner": "",
    "icmRouting": "CO+IE-Hardware Inventory/IAM Inbound Outbound",
    "flighting": "IEIS-Receive-Mobile",
    "moduleFile": "receive-mobile-app.module",
    "moduleName": "ReceiveMobileAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "receive-to-tile",
    "displayName": "Receive to Tile",
    "url": "rtt",
    "icon": "inventory_2",
    "owner": "",
    "icmRouting": "CSCP-FAST-DeploymentServices/Receiving Aggregation",
    "flighting": "",
    "moduleFile": "receive-to-tile-app.module",
    "moduleName": "ReceiveToTileAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "requests",
    "displayName": "Support Tickets",
    "url": "requests",
    "icon": "description",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "flighting": "",
    "moduleFile": "requests-app.module",
    "moduleName": "RequestsAppModule",
    "authorization": [
      {
        "resources": [
          "MicrosoftData",
          "DellData",
          "GNSData",
          "HPData",
          "LenovoData",
          "ZTData",
          "WCSData",
          "CrayData",
          "QuantaData"
        ]
      }
    ],
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "scandbd",
    "displayName": "DBD Removal",
    "url": "v3scandbd",
    "icon": "fiber_new",
    "owner": "",
    "icmRouting": "COIE-ITOps: DBD Risk Management/Triage",
    "flighting": "",
    "moduleFile": "scandbd-app.module",
    "moduleName": "ScandbdAppModule",
    "privateCloudEnabled": false,
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243",
    "authorization": [
      {
        "resources": [
          "MicrosoftData"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": [
          "AtLeastOneDbdPolicy"
        ],
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "sku-lookup",
    "displayName": "SKU Lookup",
    "url": "sku",
    "icon": "device_hub",
    "owner": "",
    "icmRouting": "CO+IE-Hardware Inventory/Triage",
    "flighting": "",
    "moduleFile": "sku-lookup-app.module",
    "moduleName": "SkuLookupAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "pages": [
      {
        "name": "sku-lookup",
        "displayName": "SKU - SKU Lookup Tool (GDCO)",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage"
      },
      {
        "name": "sku-lookup",
        "displayName": "SKU - SKU Documentation (OnePDM)",
        "url": "",
        "icon": "",
        "icmRouting": "PDM Engineering/Integrated Supply Planning and PDM Engineering"
      },
      {
        "name": "sku-lookup",
        "displayName": "SKU - SKU Change/Discrepancy (MMT)",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage",
        "icmRoutingRedirection": {
          "url": "https://gdcoticketing.visualstudio.com/Support%20Operations/_dashboards/dashboard/b55d79c0-a9fe-49ea-be61-fda73d330ed5",
          "text": "ADO Support Request",
          "message": "For SKU change requests including missing or incorrect VPNs, please use the link below to create an ADO Support Request to Materials Masters team."
        }
      }
    ],
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "tasks-dashboard",
    "displayName": "",
    "url": "tasks/dashboard",
    "icon": "",
    "owner": "",
    "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
    "flighting": "",
    "moduleFile": "tasks-dashboard-app.module",
    "moduleName": "TasksDashboardAppModule",
    "pages": [
      {
        "name": "Home",
        "displayName": "Dashboard",
        "url": "",
        "icon": "dashboard",
        "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
        "authorization": [
          {
            "resources": "MicrosoftData"
          }
        ]
      },
      {
        "name": "TaskAssignment",
        "displayName": "Task Assignment",
        "url": "Shift Lead/Tasks Assignment",
        "icon": "assignment_ind",
        "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
        "flighting": "ShiftLeadTasksAssignment",
        "authorization": [
          {
            "resources": "MicrosoftData"
          }
        ]
      }
    ],
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "authorization": [
      {
        "resources": "MicrosoftData"
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": "AtLeastOnePolicy",
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "tasks-details",
    "displayName": "Task Details",
    "url": "tasks/details",
    "icon": "",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "flighting": "",
    "moduleFile": "tasks-details-app.module",
    "moduleName": "TasksDetailsAppModule",
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "pages": [
      {
        "name": "tasks-details",
        "displayName": "Task Details (Azure RMA)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/Incident Manager"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Break/Fix)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/Incident Manager"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Content Migration)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/Incident Manager"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Critical Environment)",
        "url": "",
        "icon": "",
        "icmRouting": "Critical Environment Service/Triage"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Decommission)",
        "url": "",
        "icon": "",
        "icmRouting": "MCIO Common/DecommPD"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Deployment)",
        "url": "",
        "icon": "",
        "icmRouting": "CSCP-FAST-DeploymentServices/Deployment"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (EHS)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/CISSG"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (IAM)",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/Spare Operations & ICQA (Michelin)"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Ops Assurance)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/CISSG"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Reservation)",
        "url": "",
        "icon": "",
        "icmRouting": "COI-GDCO-ReservationService/Triage"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Simple Change)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/Incident Manager"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (SOP)",
        "url": "",
        "icon": "",
        "icmRouting": "Global Data Center Operations App and Service/Incident Manager"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (IAM ITAD Engagement)",
        "url": "",
        "icon": "",
        "icmRouting": "MCIO Common/Itad Eng"
      },
      {
        "name": "tasks-details",
        "displayName": "Task Details (Asset Inventory Review)",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage"
      }
    ],
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243",
    "authorization": [
      {
        "resources": [
          "MicrosoftData",
          "DellData",
          "GNSData",
          "HPData",
          "LenovoData",
          "ZTData",
          "WCSData",
          "CrayData",
          "QuantaData"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": "AtLeastOnePolicy",
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "tasks-list",
    "displayName": "List View",
    "url": "tasks/list",
    "icon": "view_list",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "flighting": "",
    "moduleFile": "tasks-list-app.module",
    "moduleName": "TasksListAppModule",
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "redirects": [
      {
        "from": "list",
        "to": "tasks/list"
      }
    ],
    "authorization": [
      {
        "resources": [
          "MicrosoftData",
          "DellData",
          "GNSData",
          "HPData",
          "LenovoData",
          "ZTData",
          "WCSData",
          "CrayData",
          "QuantaData"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": "AtLeastOnePolicy",
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "tasks-scheduler",
    "displayName": "Recurring Task Scheduler",
    "url": "tasks/scheduler",
    "icon": "cached",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "flighting": "",
    "moduleFile": "tasks-scheduler-app.module",
    "moduleName": "TasksSchedulerAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "warranty-updater",
    "displayName": "Warranty updater",
    "url": "warranty-updater",
    "icon": "fiber_new",
    "owner": "",
    "icmRouting": "CO+IE-Hardware Inventory/ACE Triage",
    "flighting": "",
    "moduleFile": "warranty-updater-app.module",
    "moduleName": "WarrantyUpdaterAppModule",
    "authorization": [
      {
        "resources": "WarrantyUpdaterApp"
      }
    ],
    "privateCloudEnabled": false,
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "ace-shared",
    "displayName": "ACE Shared",
    "url": "ace-shared",
    "icon": "fiber_new",
    "icmRouting": "CO+IE-Hardware Inventory/ACE Triage",
    "owner": "",
    "flighting": "",
    "moduleFile": "ace-shared-app.module",
    "moduleName": "AceSharedAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-ace-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "ce-inventory",
    "displayName": "CE Inventory",
    "url": "ce-inventory",
    "icon": "token",
    "icmRouting": "CO+IE-Hardware Inventory/ACE Triage",
    "owner": "",
    "moduleFile": "ce-inventory-app.module",
    "moduleName": "CeInventoryAppModule",
    "privateCloudEnabled": false,
    "pages": [
      {
        "name": "ce-inventory",
        "displayName": "CE Inventory Data Fix",
        "url": "",
        "icon": "",
        "icmRouting": "DCCE Asset Architecture/Triage"
      },
      {
        "name": "ce-inventory",
        "displayName": "CE Inventory GDCO App",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/ACE Triage"
      }
    ],
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-ce-inventory-ddu",
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243",
    "authorization": [
      {
        "resources": [
          "CEInventoryApp"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": [
          "AtLeastOneCEPolicy"
        ],
        "action": "IsIn"
      }
    ]
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "ce-inventory-old",
    "displayName": "CE OLD",
    "url": "ce-inventory-old",
    "icon": "fiber_new",
    "icmRouting": "CO+IE-Hardware Inventory/ACE Triage",
    "owner": "",
    "flighting": "",
    "moduleFile": "ace-shared-app.module",
    "moduleName": "CEInventoryOldAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-ce-inventory-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "cmms",
    "displayName": "CMMS",
    "url": "cmms",
    "icon": "engineering",
    "owner": "",
    "icmRouting": "CO+I Change Management/Triage",
    "flighting": "",
    "moduleFile": "cmms-app.module",
    "moduleName": "CmmsAppModule",
    "authorization": [
      {
        "resources": "ChangeManagementApplication"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-cmms-ddu"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "change-management",
    "displayName": "Change Management",
    "url": "change-management",
    "icon": "change_circle",
    "owner": "",
    "icmRouting": "CO+I Change Management/Triage",
    "flighting": "",
    "moduleFile": "change-management-app.module",
    "moduleName": "ChangeManagementAppModule",
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-dccms-ddu"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "cablemanagement",
    "displayName": "Cable Management",
    "url": "cablemanagement",
    "icon": "build",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "flighting": "NetworkDesignTemplate",
    "moduleFile": "cable-management-app.module",
    "moduleName": "CableManagementAppModule",
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-deployment-ddu",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "digitized-procedure-v2",
    "displayName": "CE Digital Procedures v2",
    "url": "digitalprocedures-v2",
    "icon": "library_books",
    "owner": "",
    "icmRouting": "Critical Environment Service/Triage",
    "flighting": "COI-DP-V2",
    "moduleFile": "digitized-procedure-v2-app.module",
    "moduleName": "DpV2AppModule",
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-dmop-ddu",
    "authorization": [
      {
        "resources": [
          "Template",
          "Procedure"
        ],
        "tenant": "CEDPServiceTest"
      }
    ]
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "docs",
    "displayName": "Docs",
    "url": "docs",
    "icon": "library_books",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Platform",
    "moduleFile": "docs-app.module",
    "moduleName": "DocsAppModule",
    "authorization": [],
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-docs-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "receive",
    "displayName": "Receive",
    "url": "receive",
    "icon": "inbox",
    "owner": "",
    "icmRouting": "CO+IE-Hardware Inventory/IAM Inbound Outbound",
    "moduleFile": "receive-app.module",
    "moduleName": "ReceiveAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-io-ddu",
    "pages": [
      {
        "name": "receive",
        "displayName": "Receive - GDCO Receive Issues",
        "url": "",
        "icon": "",
        "icmRouting": "CO+IE-Hardware Inventory/IAM Inbound Outbound"
      },
      {
        "name": "receive",
        "displayName": "Receive - SKU or VPN issues",
        "url": "",
        "icon": "",
        "icmRouting": "Procurement Operations/Materials Master"
      }
    ],
    "appInsightsKey": "5eab47f6-3734-4f69-ab97-c4e3bc985243"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "labor",
    "displayName": "Labor Management",
    "url": "labor",
    "icon": "people",
    "owner": "",
    "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
    "flighting": "",
    "moduleFile": "labor-app.module",
    "moduleName": "LaborAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "leo-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "labor-training",
    "displayName": "Labor Training",
    "url": "training",
    "icon": "local_library",
    "owner": "",
    "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
    "flighting": "",
    "moduleFile": "labor-training-app.module",
    "moduleName": "LaborTrainingAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "leo-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "reports",
    "displayName": "Reports",
    "url": "reports",
    "icon": "assessment",
    "owner": "",
    "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
    "flighting": "PowerbiEmbedded",
    "moduleFile": "reports-app.module",
    "moduleName": "ReportsAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "leo-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "workspace",
    "displayName": "Workspace",
    "url": "workspace",
    "icon": "work",
    "owner": "",
    "icmRouting": "MCIO-GDCO-LaborReferenceSystem/Triage",
    "flighting": "",
    "moduleFile": "workspace-app.module",
    "moduleName": "WorkspaceAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "leo-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "action-portal",
    "displayName": "Action Portal",
    "url": "action-portal",
    "icon": "fiber_new",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Platform",
    "flighting": "",
    "moduleFile": "action-portal-app.module",
    "moduleName": "ActionPortalAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-platform-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "message-center",
    "displayName": "Message Center",
    "url": "message",
    "icon": "",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Platform",
    "moduleFile": "message-center-app.module",
    "moduleName": "MessageCenterAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-platform-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "my-bugs",
    "displayName": "My Created Bugs",
    "url": "my-bugs",
    "icon": "",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Platform",
    "flighting": "",
    "moduleFile": "my-bugs-app.module",
    "moduleName": "MyBugsAppModule",
    "authorization": [
      {
        "resources": [
          "MicrosoftData",
          "DellData",
          "GNSData",
          "HPData",
          "LenovoData",
          "ZTData",
          "WCSData",
          "CrayData",
          "QuantaData"
        ]
      }
    ],
    "privateCloudEnabled": false,
    "redirects": [
      {
        "from": "tasks/my-bugs",
        "to": "my-bugs"
      }
    ],
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-platform-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "service-outages",
    "displayName": "Service outages",
    "url": "outages",
    "icon": "fiber_new",
    "owner": "",
    "flighting": "",
    "moduleFile": "service-outages-app.module",
    "moduleName": "ServiceOutagesAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-platform-ddu"
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "tools-links",
    "displayName": "Tools & Links",
    "url": "toolsAndLinks",
    "icon": "link",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Platform",
    "moduleFile": "tools-links-app.module",
    "moduleName": "ToolsLinksAppModule",
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "privateCloudEnabled": false,
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-platform-ddu"
  },
  {
    "$schema": "../../../tools/schemas/application-schema.json",
    "name": "runbooks",
    "displayName": "IT Procedure Management",
    "url": "runbooks",
    "icon": "dvr",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "moduleFile": "runbooks-app.module",
    "moduleName": "RunbooksAppModule",
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-runbook-ddu",
    "redirects": [
      {
        "from": "runbook-management",
        "to": "runbooks"
      }
    ],
    "authorization": [
      {
        "resources": "MicrosoftData"
      }
    ],
    "pages": [
      {
        "name": "ITProcedureManagement",
        "displayName": "IT Procedure Management",
        "url": "workspaces",
        "icon": "dvr",
        "owner": "",
        "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
        "authorization": [
          {
            "resources": "MicrosoftData"
          }
        ]
      }
    ],
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ]
  },
  {
    "$schema": "../../../../../tools/schemas/application-schema.json",
    "name": "tasks-query",
    "displayName": "Custom Query",
    "url": "tasks/query",
    "icon": "playlist_play",
    "owner": "",
    "icmRouting": "Global Data Center Operations App and Service/Incident Manager",
    "flighting": "",
    "moduleFile": "tasks-query-app.module",
    "moduleName": "TasksQueryAppModule",
    "privateCloudEnabled": true,
    "enabledForNonPublicClouds": [
      "Bleu"
    ],
    "dduRouteEnabled": true,
    "dduRouteFlighting": "DDURoute-tasks-query-ddu",
    "authorization": [
      {
        "resources": [
          "MicrosoftData",
          "DellData",
          "GNSData",
          "HPData",
          "LenovoData",
          "ZTData",
          "WCSData",
          "CrayData",
          "QuantaData"
        ]
      },
      {
        "tenant": "GDCOAppPolicyTest",
        "resources": "AtLeastOnePolicy",
        "action": "IsIn"
      }
    ]
  }
]