import { IShellApplicationBar } from '@coie/application-shell';
import { getExtensions } from './extensions/getExtensions';
import { getIcons } from './icons/getIcons';
import { Injector } from '@angular/core';

export const createCommonApplicationBar = (injector: Injector): IShellApplicationBar => {
  return {
    brandLabel: 'Global Datacenter Operations (GDCO)',
    iconPathMap: getIcons(),
    extensions: getExtensions(injector).extensions
  };
};
