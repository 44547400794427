import { formatMessage } from '@coie/application-shell';
import { Extension, ExtensionAction } from '@coie/application-shell';

export class OutageExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl: string;
    tooltip: string;
    dropdownItems?: { label: string; link: string }[];

    constructor() {
        super();
        this.name = 'outage';
        this.svgIconPath = 'assets/icons/shellv2/bug_20_regular.svg';
        this.action = 'DropdownMenu';
        this.dropdownItems = [
            { label: formatMessage('reportProblem'), link: '/requests/forms/10' },
            { label: formatMessage('myReportedProblems'), link: '/my-bugs' },
        ];
        this.tooltip = formatMessage('outageCenter');
    }

    async render(): Promise<string> {
        return `
            <div class="user-extension">
                <div class="icon" id="${this.name}-icon">${await this.getIcon()}</div>
                <div class="dropdown-menu" id="${this.name}-dropdown">
                    ${this.generateDropdownHtml()}
                </div>
            </div>
        `;
    }
}
