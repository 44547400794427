import { IApplicationBarExtensions, Extension } from '@coie/application-shell';
import { HelpExtension } from './help-extension';
import { MessageExtension } from './message-extension';
import { NotificationExtension } from './notification-extension';
import { OutageExtension } from './outage-extension';
import { PreferencesExtension } from './preferences-extension';
import { UserExtension } from './user-extension';
import { Injector } from '@angular/core';

export const getExtensions = (injector: Injector): IApplicationBarExtensions => {
  const notificationExtension = new NotificationExtension();
  const messageExtension = new MessageExtension();
  const helpExtension = new HelpExtension();
  const outageExtension = new OutageExtension();
  const preferencesExtension = new PreferencesExtension();
  const userExtension = injector.get(UserExtension);

  const DefaultExtensions: Extension[] = [
    notificationExtension,
    messageExtension,
    helpExtension,
    outageExtension,
    preferencesExtension,
    userExtension
  ];

  return {
    extensions: DefaultExtensions
  };
};
