import { formatMessage } from '@coie/application-shell';
import { Extension, ExtensionAction } from '@coie/application-shell';

export class HelpExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl?: string;
    dropdownItems: { label: string; link: string }[];
    tooltip: string;

    constructor() {
        super();
        this.name = 'help';
        this.svgIconPath = 'assets/icons/shellv2/question_20_regular.svg';
        this.action = 'DropdownMenu';
        this.dropdownItems = [
            { label: formatMessage('reportBug'), link: '/requests/forms/10' },
            { label: formatMessage('myCreatedBugs'), link: '/my-bugs' },
            { label: formatMessage('submitFeatureRequest'), link: '/requests/forms/11' },
            { label: formatMessage('dashboardViewFeatureRequests'), link: 'https://aka.ms/feature-request-powerbi-dcis' },
            { label: formatMessage('faq'), link: '/docs/faq' },
            { label: formatMessage('agGridKeyboardShortcuts'), link: 'https://www.ag-grid.com/javascript-grid/keyboard-navigation' },
            { label: formatMessage('welcomePage'), link: '/docs/welcome' },            
            { label: formatMessage('microsoftDataProtectionNotice'), link: 'https://aka.ms/dpn' },
            { label: formatMessage('microsoftPrivacyStatement'), link: 'https://aka.ms/privacy' },
            { label: `Copyright &copy; ${new Date().getFullYear()} Microsoft`, link: 'http://www.microsoft.com' },
        ];
        this.tooltip = formatMessage('help');
    }

    async render(): Promise<string> {
        return `
            <div class="user-extension">
                <div class="icon" id="${this.name}-icon">${await this.getIcon()}</div>
                <div class="dropdown-menu" id="${this.name}-dropdown">
                    ${this.generateDropdownHtml()}
                </div>
            </div>
        `;
    }
}
