import { Extension, ExtensionAction, formatMessage } from '@coie/application-shell';
import styles from './user-extension.styles';
import { GdcoAuth } from '@gdco/auth';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl?: string;
    dropdownItems: { label: string; link: string }[];
    tooltip: string;
    private _auth: GdcoAuth;

    constructor(private injector: Injector) {
        super();
        this._auth = this.injector.get(GdcoAuth);
        this.name = 'user';
        this.svgIconPath = 'assets/icons/shellv2/person_circle_20_regular.svg';
        this.action = 'DropdownMenu';
        this.dropdownItems = [
          { label: 'displayName', link: this._auth?.currentUser?.displayName },
          { label: 'email', link: this._auth?.currentUser?.userPrincipalName },
          { label: 'avatarLink', link: '' },
          { label: 'abbr', link: '' },
        ];
        this.tooltip = '';
    }

    async render(): Promise<string> {
        const styleContent = styles();
        return `
            <div class="user-extension">
            <div class="icon" id="${this.name}-icon">${await this.getIcon()}</div>
                <div class="dropdown-menu" id="${this.name}-dropdown">
                    <style>${styleContent}</style>
                    <div class="header">
                        <div class="title">Microsoft</div>
                        <a id="mectrl_body_signOut" class="sign-out" 
                            aria-label="Sign out of this account" role="button" href="javascript:void(0);">
                            ${formatMessage('signOut')}
                        </a>
                    </div>
                    <div class="profile">
                        <div class="avatar" style="background-image: url('${this.dropdownItems.find(item => item.label === 'avatarLink')?.link || ''}');">
                            ${!this.dropdownItems.find(item => item.label === 'avatarLink') ? (this.dropdownItems.find(item => item.label === 'abbr')?.link || 'UU') : ''}
                        </div>
                        <div class="details">
                            <div class="name">${this.dropdownItems.find(item => item.label === 'displayName')?.link || 'not found'}</div>
                            <div class="email">${this.dropdownItems.find(item => item.label === 'email')?.link || 'not found'}</div>
                            <div class="account-link">
                                <a href="https://myaccount.microsoft.com/" target="_blank">
                                    ${formatMessage('viewAccount')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}
