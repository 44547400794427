import { formatMessage } from '@coie/application-shell';
import { Extension, ExtensionAction } from '@coie/application-shell';

export class MessageExtension extends Extension {
    name: string;
    svgIconPath: string;
    action: ExtensionAction;
    navigateUrl: string;
    tooltip: string;
    dropdownItems?: { label: string; link: string }[];

    constructor() {
        super();
        this.name = 'message';
        this.svgIconPath = 'assets/icons/shellv2/comment_note_20_regular.svg';
        this.action = 'DropdownMenu';
        this.dropdownItems = [
            { label: formatMessage('submitFeatureRequest'), link: '/requests/forms/11' },
            { label: formatMessage('dashboardViewFeatureRequests'), link: 'https://aka.ms/feature-request-powerbi-dcis' },
        ];
    }

    async render(): Promise<string> {
        return `
            <div class="user-extension">
                <div class="icon" id="${this.name}-icon">${await this.getIcon()}</div>
                <div class="dropdown-menu" id="${this.name}-dropdown">
                    ${this.generateDropdownHtml()}
                </div>
            </div>
        `;
    }
}
