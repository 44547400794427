import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IShellApplicationBar,
    shellApplicationBarSubscription,
    setShellApplicationBar,
    setShellTheme,
    IShellTheme,
    ThemeDefaultState,
    ThemeState,
    ShellAPI
} from '@coie/application-shell';
import { createCommonApplicationBar } from '../../../application-shell-behavior/src/common-application-bar';
import { GdcoAuth } from '@gdco/auth';

@Injectable({
  providedIn: 'root'
})
export class Shellv2Service {
  private themeSubject: BehaviorSubject<IShellTheme>;
  public theme$: Observable<IShellTheme>;

  private applicationBarSubject: BehaviorSubject<IShellApplicationBar>;
  public applicationBar$: Observable<IShellApplicationBar>;

  commonApplicationBar: IShellApplicationBar;

  constructor(
    private injector: Injector,
    private _auth: GdcoAuth,
  ) {
    this.commonApplicationBar = createCommonApplicationBar(this.injector);
    this.applicationBarSubject = new BehaviorSubject<IShellApplicationBar>(this.commonApplicationBar);
    this.applicationBar$ = this.applicationBarSubject.asObservable();

    const themeDefaultState: ThemeState = ThemeDefaultState;
    this.themeSubject = new BehaviorSubject<IShellTheme>(
      {
        themeName: themeDefaultState.themeName,
        theme: themeDefaultState.theme
      }
    );
    this.theme$ = this.themeSubject.asObservable();

    shellApplicationBarSubscription((applicationBar: IShellApplicationBar) => {
      this.applicationBarSubject.next(applicationBar);
    });

    this.attachSignoutLinkEvent();
  }

  getApplicationBar(): IShellApplicationBar {
    return this.applicationBarSubject.getValue();
  }

  useCustomShellApplicationBarEffect(applicationBar: IShellApplicationBar): void {
    this.applicationBarSubject.next(applicationBar);
    setShellApplicationBar(applicationBar);
  }

  useCustomShellThemeEffect(theme: IShellTheme): void {
    this.themeSubject.next(theme);
    setShellTheme(theme);
  }

  useShellAPI() {
    return ShellAPI;
  }

private attachSignoutLinkEvent(): void {
  let attempts = 0;
  const maxAttempts = 10;
  const intervalId = setInterval(() => {
    const coieApplicationBar = document.querySelector('coie-application-bar');
    if (coieApplicationBar && (coieApplicationBar as HTMLElement).shadowRoot) {
      const signOutLink = (coieApplicationBar as HTMLElement).shadowRoot.querySelector('#mectrl_body_signOut');
      if (signOutLink) {
        signOutLink.addEventListener('click', () => {
          this._auth.logout();
        });
        clearInterval(intervalId);
      }
    }

    attempts++;
    if (attempts >= maxAttempts) {
      clearInterval(intervalId);
    }
    }, 1000); // stop checking once the user card signout link is available
  }
}
