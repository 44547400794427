import { Injectable } from '@angular/core';
import { DpPermissionAccessorV2 } from '../accessors-v2';
import { Action, ActionPayloadContext, awaitable } from '@gdco/store';
import { DpV2EmailResponse } from '../model';

@Injectable()
export class DpV2PermissionManager {
  constructor(private readonly _permissionAccessor: DpPermissionAccessorV2) {}

  @Action()
  getEmails(context: ActionPayloadContext<{ email: string }>): Promise<DpV2EmailResponse> {
    return awaitable(this._permissionAccessor.getEmails(context.payload.email));
  }
}
