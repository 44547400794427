/**
 * Represents the feedback provided by the user for an assistant message.
 */
export class AssistantMessageFeedback {
  source_id: string;
  source_name: string;
  source_type: string;
  session_id: string;
  ticket_id: string;

  source_data: string;
  source_data_timestamp: string;

  user_id: string;
  user_email: string;
  user_request: string;

  has_incorrect_results: boolean;
  has_incomplete_results: boolean;
  misunderstood_prompt: boolean;
  has_presentation_issue: boolean;
  has_security_concern: boolean;

  feedback_details: string;
  contact_preference: 'yes' | 'no';
  is_positive: boolean;

  public constructor(init?: Partial<AssistantMessageFeedback>) {
    this.source_id = init?.source_id ?? '';
    this.source_name = init?.source_name ?? '';
    this.source_type = init?.source_type ?? 'assistant';
    this.session_id = init?.session_id ?? '';
    this.ticket_id = init?.ticket_id ?? '';
    this.source_data = init?.source_data ?? '';
    this.source_data_timestamp = init?.source_data_timestamp ?? '';
    this.feedback_details = init?.feedback_details ?? '';
    this.user_id = init?.user_id ?? '';
    this.user_email = init?.user_email ?? '';
    this.user_request = init?.user_request ?? '';

    this.has_incorrect_results = init?.has_incorrect_results ?? false;
    this.has_incomplete_results = init?.has_incomplete_results ?? false;
    this.misunderstood_prompt = init?.misunderstood_prompt ?? false;
    this.has_presentation_issue = init?.has_presentation_issue ?? false;
    this.has_security_concern = init?.has_security_concern ?? false;
    this.contact_preference = init?.contact_preference ?? 'yes';
    this.is_positive = init?.is_positive ?? true;
  }
}