import { UserIdentity } from '@gdco/auth';

export interface ZiplineWebComponentElement {
  user: {
    identity: UserIdentity;
    settings: any;
  };
  onAppComponentReady: () => void;
  setProps: (props: any) => void;
  isAppComponentReady: boolean;
  showUI: boolean;
  dispatchEvent: (event: CustomEvent) => void;
  render: () => void;
  componentReadyCallback: () => void;
}

export enum ZiplineRequestType {
  TASKS_DETAILS = 'tasks-details',
  POETRY_GENERATION = 'poetry-generation',
}
