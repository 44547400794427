import { Injectable, Injector } from '@angular/core';
import { IShellApplicationBar, IShellTheme, ThemeDefaultState, ThemeState } from '@coie/application-shell';
import { BehaviorSubject, Observable } from 'rxjs';
import { createCommonApplicationBar } from '../../../application-shell-behavior/src/common-application-bar';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private themeSubject: BehaviorSubject<IShellTheme>;
  public theme$: Observable<IShellTheme>;

  private applicationBarSubject: BehaviorSubject<IShellApplicationBar>;
  public applicationBar$: Observable<IShellApplicationBar>;
  commonApplicationBar: IShellApplicationBar;

  constructor(private injector: Injector) {
    const themeDefaultState: ThemeState = ThemeDefaultState;

    this.themeSubject = new BehaviorSubject<IShellTheme>(
        {
            themeName: themeDefaultState.themeName,
            theme: themeDefaultState.theme
        }
    );
    this.theme$ = this.themeSubject.asObservable();

    this.commonApplicationBar = createCommonApplicationBar(this.injector);
    this.applicationBarSubject = new BehaviorSubject<IShellApplicationBar>(this.commonApplicationBar);
    this.applicationBar$ = this.applicationBarSubject.asObservable();
  }

  getTheme(): IShellTheme {
    return this.themeSubject.getValue();
  }

  setTheme(theme: IShellTheme): void {
    this.themeSubject.next(theme);
  }

  getApplicationBar(): IShellApplicationBar {
    return this.applicationBarSubject.getValue();
  }

  setApplicationBar(applicationBar: IShellApplicationBar): void {
    this.applicationBarSubject.next(applicationBar);
  }
}
